import { Navbar } from 'flowbite-react';
import Logo from '../images/logo_izzyfin.png';
import { motion } from 'framer-motion';
import { useState } from 'react';

export default function HeaderNavbar() {
  const path = document.location.href.split("/")[3];
  const [menu, setMenu] = useState(path)

  return (
    <motion.div
      className='sticky top-0 w-full z-50'
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
      <Navbar fluid rounded>
        <Navbar.Brand href="https://izzyfin.com/">
          <img src={Logo} className="mr-3 h-9 sm:h-12" alt="Izzyfin Logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link href="#home" active={menu === '#home'} onClick={() => setMenu("#home")}>
            Home
          </Navbar.Link>
          <Navbar.Link href="#feature" active={menu === '#feature'} onClick={() => setMenu("#feature")}>Feature</Navbar.Link>
          <Navbar.Link href="#contact" active={menu === '#contact'} onClick={() => setMenu("#contact")}>Contact</Navbar.Link>
          <Navbar.Link href="#privacy" active={menu === '#privacy'} onClick={() => setMenu("#privacy")}>Privacy Policy</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </motion.div>
  );
}