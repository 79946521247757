import { motion } from 'framer-motion';

interface FeatureCardProp {
    title: string;
    subtitle: string;
    icon: string;
    image: string;
}
export function FeatureCard({
    title,
    subtitle,
    icon,
    image
}: FeatureCardProp) {
    return (
        <motion.div
            className='flex flex-col justify-between max-h-[800px] items-center lg:w-1/4 bg-gradient-to-br from-cyan-500 to-blue-500  rounded-3xl px-5 lg:px-0 pt-5 hover:bg-gradient-to-br hover:from-cyan-400 hover:to-blue-400'
            whileHover={{ scale: [null, 1.05, 1.1] }}
            transition={{ duration: 0.3 }}
        >
            <img src={icon} className='w-16' alt={`${title} icon`} />
            <span className='max-w-2xl mb-4 text-xl font-extrabold tracking-tight text-white leading-none md:text-2xl xl:text-2xl dark:text-white mt-2 '>{title}</span>
            <span className='max-w-2xl mb-4 text-lg text-gray-300 text-center'>
                {subtitle}
            </span>
            <div className="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-t-[2.5rem] h-[380px] w-[300px]">
                <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                <div className="rounded-t-[2rem] overflow-hidden w-[272px] h-[366px] bg-white dark:bg-gray-800">
                    <img src={image} className="w-[272px] h-[370px] object-cover object-top" alt="" />
                </div>
            </div>
        </motion.div>
    )
}